import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Home from '../views/Home2.vue'
// import Futures from '../views/Futures.vue'
// import Shares from '../views/Shares.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页 - 大盘手网'
    },
    component: Home
  },
  // {
  //   path: '/newHome',
  //   name: 'newHome',
  //   meta: {
  //     title: '首页 - 大盘手网'
  //   },
  //   component: Home2
  // },
  {
    path: '/changeType',
    name: 'ChangeType',
    meta: {
      title: '修改账户类型 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "changeType" */ '../views/ChangeType.vue')
  },
  {
    path: '/qrcode',
    name: 'QRCode',
    meta: {
      title: '入会凭证 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "qrcode" */ '../views/QCode.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    meta: {
      title: '添加人员 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue')
  },
  {
    path: '/activityApply',
    name: 'ActivityApply',
    meta: {
      keepAlive: true,
      title: '在线报名 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "activityApply" */ '../views/ActivityApply.vue')
  },
  {
    path: '/activityScan',
    name: 'ActivityScan',
    meta: {
      title: '扫码签到 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "activityScan" */ '../views/ActivityScan.vue')
  },
  // {
  //   path: '/activityInvitation',
  //   name: 'ActivityInvitation',
  //   meta: {
  //     title: '颁奖盛典 - 大盘手网',
  //     shareTitle: '大盘手网首届颁奖盛典邀请函',
  //     desc: '期货奥斯卡，山城英雄荟（2021.07.10，重庆）',
  //     shareImg: 'https://m.dpswang.com/uploads/images/202107/activity_2021_icon.jpg'
  //   },
  //   component: () => import(/* webpackChunkName: "activityInvitation" */ '../views/ActivityInvitation.vue')
  // },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/wechat_exit',
    name: 'wechat_exit',
    component: () => import(/* webpackChunkName: "wechat_exit" */ '../views/wechat_exit.vue')
  },
  {
    path: '/sevenfoxes',
    name: 'SevenFoxes',
    meta: {
      title: '七尾狐分析神器 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "sevenfoxes" */ '../views/SevenFoxes.vue')
  },
  {
    path: '/report',
    name: 'Report',
    meta: {
      title: '新闻中心 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue')
  },
  {
    path: '/news',
    name: 'Newa',
    meta: {
      title: '咨询中心 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
  },
  {
    path: '/videos',
    name: 'Videos',
    meta: {
      title: '视频中心 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "videos" */ '../views/videos.vue')
  },
  {
    path: '/rule',
    name: 'Rule',
    meta: {
      title: '大赛规则 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "rule" */ '../views/Rule.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    meta: {
      title: '大赛统计 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.vue')
  },
  {
    path: '/secret',
    name: 'Secret',
    meta: {
      title: '实战秘籍 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "secret" */ '../views/Secret.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      title: '文章详情 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "detail" */ '../views/Detail.vue')
  },
  {
    path: '/help',
    name: 'Help',
    meta: {
      title: '帮助中心 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    path: '/wechatAuth',
    name: 'WechatAuth',
    meta: {
      title: '微信授权 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "wechatAuth" */ '../views/WechatAuth.vue')
  },
  {
    path: '/bindUser',
    name: 'BindUser',
    meta: {
      title: '绑定用户 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "bindUser" */ '../views/BindUser.vue')
  },
  {
    path: '/mom',
    name: 'Mom',
    meta: {
      title: '大盘手网'
    },
    component: () => import(/* webpackChunkName: "mom" */ '../views/Mom.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/mobileLogin',
    name: 'MobileLogin',
    meta: {
      title: '登录 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "mobileLogin" */ '../views/MobileLogin.vue')
  },
  {
    path: '/forgetPass',
    name: 'ForgetPass',
    meta: {
      title: '忘记密码 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "forgetPass" */ '../views/ForgetPass.vue')
  },
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   meta: {
  //     title: '注册 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue')
  // },
  {
    path: '/gvrp',
    name: 'Gvrp',
    meta: {
      title: '用户协议 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "gvrp" */ '../views/Gvrp.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      needLogin: true,
      title: '账户详情 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/s1account',
    name: 'S1Account',
    meta: {
      needLogin: true,
      title: '账户详情 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "s1account" */ '../views/S1Account.vue')
  },
  {
    path: '/s2account',
    name: 'S2Account',
    meta: {
      needLogin: true,
      title: '账户详情 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "s2account" */ '../views/S2Account.vue')
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    mmeta: {
      needLogin: true,
      title: '用户详情 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "userinfo" */ '../views/UserInfo.vue')
  },
  {
    path: '/user',
    meta: {
      needLogin: true,
      title: '个人中心 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
    children: [
      {
        path: '',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Index.vue')
      },
      // {
      //   path: 'account',
      //   meta: {
      //     needLogin: true,
      //     title: '我的账户 - 大盘手网'
      //   },
      //   component: () => import(/* webpackChunkName: "user" */ '../views/user/Account.vue')
      // },
      {
        path: 'account',
        meta: {
          needLogin: true,
          title: '我的账户 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/AccountNew.vue')
      },
      {
        path: 'invitation',
        meta: {
          needLogin: true,
          title: '邀请好友 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Invitation.vue')
      },
      {
        path: 'invite',
        meta: {
          needLogin: true,
          title: '邀请好友 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Invite.vue')
      },
      {
        path: 'sharesInvite',
        meta: {
          needLogin: true,
          title: '邀请好友 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/InviteShares.vue')
      },
      {
        path: 'setting',
        meta: {
          needLogin: true,
          title: '用户设置 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Setting.vue')
      },
      {
        path: 'showSetting',
        meta: {
          needLogin: true,
          title: '展示设置 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/ShowSetting.vue')
      },
      {
        path: 'message',
        meta: {
          needLogin: true,
          title: '我的消息 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Message.vue')
      },
      {
        path: 'userInfo',
        meta: {
          needLogin: true,
          title: '个人资料 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/UserInfo.vue')
      },
      {
        path: 'setGroup',
        meta: {
          needLogin: true,
          title: '个人资料 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/SettingGroup.vue')
      },
      {
        path: 'security',
        meta: {
          needLogin: true,
          title: '账户安全 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Security.vue')
      },
      {
        path: 'editMobile',
        meta: {
          needLogin: true,
          title: '账户安全 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/EditMobile.vue')
      },
      {
        path: 'addAccount',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/AddAccount.vue')
      },
      {
        path: 'addSharesAccount',
        meta: {
          needLogin: true,
          hideFooter: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/AddSharesAccount.vue')
      },
      {
        path: 'editAccount',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/EditAccount.vue')
      },
      {
        path: 'editScAccount',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/EditSharesAccount.vue')
      },
      {
        path: 'editPass',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/EditPass.vue')
      },
      {
        path: 'editMobile',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/EditMobile.vue')
      },
      {
        path: 'focus',
        meta: {
          needLogin: true,
          keepAlive: true,
          savedPosition: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Focus.vue')
      },
      {
        path: 'level',
        meta: {
          needLogin: true,
          title: '个人中心 - 大盘手网'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Level.vue')
      }
    ]
  },
  {
    path: '/rank',
    component: () => import(/* webpackChunkName: "rank" */ '../views/Rank.vue'),
    children: [
      {
        name: 'index',
        path: '',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Index.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'heavy',
        path: 'heavy',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Heavy.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'master',
        path: 'master',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Master.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'month',
        path: 'month',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Month.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'quarter',
        path: 'quarter',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Quarter.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'group',
        path: 'group',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Group.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'benefit',
        path: 'benefit',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Benefit.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      },
      {
        name: 'feature',
        path: 'feature',
        component: () => import(/* webpackChunkName: "rank" */ '../views/rank/Feature.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '大赛排名 - 大盘手网'
        }
      }
    ]
  },
  {
    path: '/history',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "history" */ '../views/history/Index.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '往届排名 - 大盘手网'
        }
      },
      {
        path: 'group',
        component: () => import(/* webpackChunkName: "history" */ '../views/history/Group.vue'),
        meta: {
          keepAlive: true,
          savedPosition: true,
          title: '往届排名 - 大盘手网'
        }
      }
    ]
  },
  {
    path: '/performance',
    name: 'Performance',
    meta: {
      hideFooter: true,
      title: '业绩鉴证 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "performance" */ '../views/Performance.vue')
  },
  {
    path: '/performanceAccount',
    name: 'PerformanceAccount',
    meta: {
      hideFooter: true,
      title: '业绩鉴证 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "performanceAccount" */ '../views/PerformanceAccount.vue')
  },
  {
    path: '/performanceProduct',
    name: 'PerformanceProduct',
    meta: {
      hideFooter: true,
      title: '产品业绩 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "performanceProduct" */ '../views/PerformanceProduct.vue')
  },
  {
    path: '/perProductAccount',
    name: 'PerProductAccount',
    meta: {
      hideFooter: true,
      title: '产品业绩 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "perProductAccount" */ '../views/PerProductAccount.vue')
  },
  // {
  //   path: '/honor',
  //   name: 'Honor',
  //   component: () => import(/* webpackChunkName: "Honor" */ '../views/Honor.vue'),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "Honor" */ '../views/honor/Index.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         hideFooter: true,
  //         title: '荣耀殿堂 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'h3',
  //       path: 'h3',
  //       component: () => import(/* webpackChunkName: "Honor" */ '../views/honor/Index2022.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         hideFooter: true,
  //         title: '荣耀殿堂 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'h2',
  //       path: 'h2',
  //       component: () => import(/* webpackChunkName: "Honor" */ '../views/honor/Index2021.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         hideFooter: true,
  //         title: '荣耀殿堂 - 大盘手网'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/certificate',
    name: 'Certificate',
    meta: {
      hideFooter: true,
      title: '证书查询 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "certificate" */ '../views/Certificate.vue')
  },
  // 股票大赛
  {
    path: '/sharesrule',
    name: 'SharesRule',
    meta: {
      title: '股票大赛规则 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "sharesrule" */ '../views/RuleShares.vue')
  },
  {
    path: '/sharesaward',
    name: 'SharesAward',
    meta: {
      title: '股票奖项设置 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "sharesaward" */ '../views/AwardShares.vue')
  },
  // {
  //   path: '/sharesrank',
  //   name: 'SharesRank',
  //   meta: {
  //     title: '股票大赛排名 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "rank" */ '../views/RankShares.vue'),
  //   children: [
  //     {
  //       name: 'index',
  //       path: '',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/rankshares/Index.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '大赛排名 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'month',
  //       path: 'month',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/rankshares/Month.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '大赛排名 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'week',
  //       path: 'week',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/rankshares/Week.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '大赛排名 - 大盘手网'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/sharesaccount',
  //   name: 'SharesAccount',
  //   meta: {
  //     needLogin: true,
  //     title: '账户详情 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "sharesaccount" */ '../views/AccountShares.vue')
  // },
  {
    path: '/open',
    name: 'Open',
    meta: {
      title: '开户 - 大盘手网'
    },
    component: () => import('../views/Open.vue')
  },
  {
    path: '/book',
    name: 'Book',
    meta: {
      needLogin: false,
      hideFooter: true,
      title: '书籍介绍 - 大盘手网'
    },
    component: () => import('../views/Book.vue')
  },
  // {
  //   path: '/sharesUpload',
  //   name: 'SharesUpload',
  //   meta: {
  //     needLogin: true,
  //     title: '数据上报 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "sharesUpload" */ '../views/SharesUpload.vue')
  // },
  // {
  //   path: '/sharesUploadHistory',
  //   name: 'SharesUploadHistory',
  //   meta: {
  //     needLogin: true,
  //     title: '数据上报 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "sharesUploadHistory" */ '../views/SharesUploadList.vue')
  // },
  // {
  //   path: '/sharesUploadDetail',
  //   name: 'SharesUploadDetail',
  //   meta: {
  //     needLogin: true,
  //     title: '数据上报 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "sharesUploadDetail" */ '../views/SharesUploadDetail.vue')
  // },
  {
    path: '/futures-quit-apply',
    name: 'FuturesQuitApply',
    meta: {
      needLogin: true,
      title: '退赛申请 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "futuresQuitApply" */ '../views/futures-quit-apply.vue')
  },
  {
    path: '/honorShare',
    name: 'HonorShare',
    meta: {
      needLogin: true,
      title: '荣誉分享 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "honorShare" */ '../views/HonorShare.vue')
  },
  // {
  //   path: '/hotStocks',
  //   name: 'HotStocks',
  //   meta: {
  //     needLogin: false,
  //     title: '热门股票 - 大盘手网'
  //   },
  //   component: () => import(/* webpackChunkName: "HotStocks" */ '../views/HotStocks.vue')
  // },
  // pk
  // {
  //   path: '/play',
  //   component: () => import(/* webpackChunkName: "rank" */ '../views/Play.vue'),
  //   children: [
  //     {
  //       name: 'index',
  //       path: '',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/Index.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '竞技场进行中 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'latest',
  //       path: 'latest',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/Latest.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '最新擂台 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'latest1v1',
  //       path: 'latest1v1',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/Latest1v1.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '最近1v1 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'end',
  //       path: 'end',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/End.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '已完结 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'victory',
  //       path: 'victory',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/Victory.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '胜率榜 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'integral',
  //       path: 'integral',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/Integral.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '积分榜 - 大盘手网'
  //       }
  //     },
  //     {
  //       name: 'myChallenge',
  //       path: 'myChallenge',
  //       component: () => import(/* webpackChunkName: "rank" */ '../views/play/MyChallenge.vue'),
  //       meta: {
  //         keepAlive: true,
  //         savedPosition: true,
  //         title: '我的战绩 - 大盘手网'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/addChalleng',
    name: 'AddChalleng',
    meta: {
      needLogin: false,
      hideFooter: true,
      title: '发起挑战 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "addChalleng" */ '../views/play/AddChalleng.vue')
  },
  {
    path: '/vsPersonal',
    name: 'VsPersonal',
    meta: {
      needLogin: false,
      title: '选手信息 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "vsPersonal" */ '../views/play/VsPersonal.vue')
  },
  // 百科
  {
    path: '/trader',
    name: 'Trader',
    meta: {
      hideFooter: true,
      title: '盘手百科 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "trader" */ '../views/Trader.vue')
  },
  {
    path: '/traderAccount',
    name: 'TraderAccount',
    meta: {
      hideFooter: true,
      title: '盘手百科 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "traderAccount" */ '../views/TraderAccount.vue')
  },
  {
    path: '/message',
    name: 'Message',
    meta: {
      needLogin: true,
      title: '消息 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "message" */ '../views/Message.vue')
  },
  {
    path: '/contrast',
    name: 'Contrast',
    meta: {
      hideFooter: true,
      title: '账户对比 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "contrast" */ '../views/Contrast.vue')
  },
  {
    path: '/appDownLoad',
    name: 'AppDownLoad',
    meta: {
      hideFooter: true,
      title: 'APP下载 - 大盘手网'
    },
    component: () => import(/* webpackChunkName: "appDownLoad" */ '../views/AppDownLoad.vue')
  },
  {
    path: '/misc/dzqh',
    name: 'DzqhReg',
    meta: {
      hideFooter: true,
      title: '东证期货大赛报名'
    },
    component: () => import(/* webpackChunkName: "dzqh" */ '../views/misc/Dzqh.vue')
  },
  {
    path: '/submatch',
    meta: {
      hideFooter: true,
      title: '东证期货大赛'
    },
    component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Index.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Rank.vue'),
        meta: {
          savedPosition: true,
          title: '东证期货大赛排名 - 大盘手网'
        }
      },
      {
        path: 'rule',
        component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Rule.vue'),
        meta: {
          savedPosition: true,
          title: '东证期货大赛规则 - 大盘手网'
        }
      },
      {
        path: 'award',
        component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Award.vue'),
        meta: {
          savedPosition: true,
          title: '东证期货大赛奖项设置 - 大盘手网'
        }
      },
      {
        path: 'signup',
        component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Signup.vue'),
        meta: {
          savedPosition: true,
          title: '东证期货大赛报名 - 大盘手网'
        }
      },
      {
        path: 'speed',
        component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Speed.vue'),
        meta: {
          savedPosition: true,
          title: '东证期货大赛报名 - 大盘手网'
        }
      }]
  },
  {
    path: '/submatch/account',
    component: () => import(/* webpackChunkName: "submatch" */ '../views/submatch/Account.vue'),
    meta: {
      savedPosition: true,
      title: '东证期货大赛账户详情 - 大盘手网'
    }
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// push
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.saveSrollTop) {
        from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      }
    }
  }
})

export default router
